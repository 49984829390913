<template>
  <!-- <div style="margin: 12px 24px"> -->
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="48">
          <el-col :md="8" :sm="24">
            <el-form-item label="模板名称：">
              <el-input
                v-model="templateName"
                placeholder="请输入"
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-button type="primary" @click="listTemplate" size="small">查 询</el-button>
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex">
        <span>报告模板</span>
        <div class="flex_1"></div>
        <el-button
          type="primary"
          size="mini"
          @click="opentemplate"
        >新建模板</el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="dataList"
          fit
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '模板名称'">
                {{scope.row.dataTemplateName}}
              </template>
              <template v-else-if="item.label === '创建时间'">
                <span>{{ scope.row.createTime.substring(0,19)}}</span>
              </template>
              <template v-else-if="item.label === '创建者'">
                {{scope.row.creatorName}}
              </template>
              <template   v-else-if="item.label === '操作'">
                <span class="el-dropdown-link" @click="editTemplate(scope.row)">
                   编辑
                </span>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        style="float: right;margin-right: 20px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
    <add-template @updateView="listTemplate" :show="templateDialog" :editTemplateObject="editTemplateObject" @close="templateDialog = false"></add-template>
  </div>
</template>

<script>

  import { listTemplate} from '@/api/senate/report'
  import datePicker from '@/components/date-picker'
  import addTemplate from '@/components/senate/add-template'
  import TextButton from '@/components/TextButton'

  // 默认展示列
  const baseColSetting = [
    {
      label: '模板名称',
      prop: 'campusName',
      state: true,
      align: 'center',
      width: '120'
    },
    {
      label: '创建时间',
      prop: 'open_date',
      state: true,
      sortable: true,
      align: 'center',
      width: '150'
    },
    {
      label: '创建者',
      prop: '',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '操作',
      state: true,
      align: 'center',
      width: '100'
    },
  ]
  export default {
    name:'reportTemplate',
    components: {datePicker,TextButton,addTemplate},
    computed: {
      defaultHeader() {
        if(localStorage.getItem('perms').indexOf('template:edittemplate') === -1) {
          this.colSetting[this.colSetting.length - 1].state = false
        }
        return this.colSetting.filter(item => item.state)
      },
    },
    mounted() {
      this.listTemplate()
    },

    data() {
      return {
        editTemplateObject:null,
        templateName:'',
        dataList: [],
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        templateDialog:false,
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
      }
    },
    methods: {
      editTemplate(row) {
        this.editTemplateObject = row
        this.templateDialog = true
      },
      opentemplate() {
        this.editTemplateObject = null,
        this.templateDialog = true
      },
      search(){
        this.listTemplate()
      },
      // 获取课程评价列表
      async listTemplate () {
        const res = await listTemplate({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          dataTempalteName:this.templateName
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },
      // 分页
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.listTemplate()
      },
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.listTemplate()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "~@/style/container.scss";
</style>
