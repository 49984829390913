<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="titleText"></span>
    </div>

    <el-row >
      <el-form ref="formData" :rules="rules" :model="formData" label-width="120px">
        <el-form-item label="模板名称" prop="title" style="width:45%;" >
          <el-input v-model="formData.title" placeholder="请输入教室名称"></el-input>
        </el-form-item>
        <el-form-item label="选择模块">
          <el-select v-model="selectMoudleList" @change="handleSelectChange"  collapse-tags multiple placeholder="请选择">
            <el-option
              v-for="item in moudleList"
              :key="item.modelId"
              :label="item.dataModelName"
              :value="item.modelId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="已选模块：">
          <draggable v-model="hasSelectMoudleList" @update="datadragEnd" :options="{animation:200}">
            <el-tag
              style="cursor: pointer;"
              v-for="(item, index) in hasSelectMoudleList"
              :key="item.modelId"
              closable
              type="info"
              @close="tagDel(index)"
            >
              <span>{{item.dataModelName}}</span>
            </el-tag>
          </draggable>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row slot="footer" class="text-right">
      <el-button @click="close" type="info" size="medium">取 消</el-button>
      <el-button type="primary" @click="confirm" size="medium">确 定</el-button>
    </el-row>

  </el-dialog>
</template>

<script>
  import { listModel,saveTemplate,editTemplate} from '@/api/senate/report'
  import draggable from 'vuedraggable'

  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
      editTemplateObject: {
        default: () => {
          return null
        },
        type: Object
      }
    },

    components:{draggable},

    data () {
      return {
        formData: {},
        hasSelectMoudleList:[],
        selectMoudleList:[],
        titleText:'',
        campusArray: [], // 校区列表
        rules: {
          title: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        },
        moudleList: [],
      }
    },

    methods: {
      handleSelectChange(){
        this.hasSelectMoudleList = []
        this.selectMoudleList.forEach((item) => {
          this.moudleList.forEach((value) => {
            if(item === value.modelId)
            this.hasSelectMoudleList.push(value)
          })
        })
      },
      async listModel() {
        const response = await listModel({})
        if(response && response.state === 'success') {
          this.moudleList = response.body
        }
        if(this.editTemplateObject !== null) {
          this.$nextTick(()=> {
            this.selectMoudleList = this.editTemplateObject.modelIds.split(',')
            this.selectMoudleList.forEach((modelId) => {
              this.moudleList.forEach((item) => {
                if(modelId === item.modelId) {
                  this.hasSelectMoudleList.push(item)
                }
              })
            })
          })

        }
      },
      tagDel (index) {
        this.$confirm('是否删除当前模块', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.selectMoudleList.forEach((item,selectIndex)=> {
              if(item === this.hasSelectMoudleList[index].modelId){
                this.selectMoudleList.splice(selectIndex, 1)
              }
            })
            this.hasSelectMoudleList.splice(index,1)
            window.$msg('删除成功')
          })
          .catch(() => {
            return false
          })
      },
      async datadragEnd (evt) {
        evt.preventDefault()

        // 遍历数组,将索引值赋值到对应的sort_order上面,完成排序
        // console.log('拖动前的索引 :' + JSON.stringify(this.formData.seleTopic))
      },
      openDialog () {
        this.listModel();
        this.formData = {
          title: '',
        }
        this.selectMoudleList = []
        this.hasSelectMoudleList = []
        if (this.editTemplateObject === null) {
          this.titleText = '新增模板'
          this.$nextTick(()=> {
            this.$refs.formData.clearValidate()
          })
        } else {
          this.titleText = '编辑模板'
          this.formData.title =  this.editTemplateObject.dataTemplateName
        }
      },

      close () { this.$emit('close') },

      confirm () {
        this.$refs.formData.validate(valid => {
          if (valid) {
            if (this.editTemplateObject === null) {
              let thembString = ''
              if(this.hasSelectMoudleList.length === 1) {
                thembString = thembString
              }else {
                this.hasSelectMoudleList.forEach((item) => {
                  if(thembString !== '') {
                    thembString = thembString+"," + item.modelId
                  }else {
                    thembString = item.modelId
                  }
                })
              }

              saveTemplate({
                dataTemplateName:this.formData.title,
                modelIds: thembString
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('新增成功')
                  this.$emit('updateView')
                  this.close()
                }
              })
            } else {
              let thembString = ''
              if(this.hasSelectMoudleList.length === 1) {
                thembString = thembString
              }else {
                this.hasSelectMoudleList.forEach((item) => {
                  if(thembString !== '') {
                    thembString = thembString+"," + item.modelId
                  }else {
                    thembString = item.modelId
                  }
                })
              }
              editTemplate({
                dataTemplateId:this.editTemplateObject.dataTemplateId,
                dataTemplateName:this.formData.title,
                modelIds: thembString
              }).then(res => {
                if (res.state === 'success') {
                  window.$msg('编辑成功')
                  this.$emit('updateView')
                  this.close()
                }
              })
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/style/dialog.scss";
</style>
